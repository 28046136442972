export default {
  data: () => ({
    amount: 0,
    propsAmount: 0,
    delivery_fee: 0,
  }),
  computed: {
    usePropsAmount() {
      // TODO
      return false
    }
  },
  methods: {
    handleResetTotal() {
      this.getChildrenTotal()
    },
    async getChildrenTotal() {
      if(this.usePropsAmount) {
        this.amount = this.propsAmount
        return
      }

      await this.$nextTick()
      this.amount = Math.round(this.$children.reduce((arr, cur) => {
        if (cur.total) arr += Number(cur.total)
        return arr
      }, 0))
    },
  },
}